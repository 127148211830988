@import "normalize.css";
@import "./bs-navbar.css";
@import "basscss-btn";
@import "basscss-btn-primary";
@import "basscss";
@import "basscss-responsive-layout";
:root {
  --button-background-color: grey;
  --center: {
    margin-left: auto;
    margin-right: auto;
  }
}
/*Navbar:*/
body {
  padding-top: 51px;
}
.navbar-brand {
  height: auto;
}
.navbar-fixed-top {
  border-width: 1px;
  border-top: none;
  border-radius: 0 0 8px 8px;
}
/*back-top:*/
.back-top {
  right: 5px;
  bottom: 5px;
  @media (--breakpoint-sm) {
    right: 15px;
    bottom: 15px;
  }
}
/*Lighten Text*/
p, li, .anchorjs-link {
  color: #333;
}
@media (width <= 48rem) {
  .container {
    margin-right: var(--space-1);
    margin-left: var(--space-1);
  }
  .anchorjs-link {
    float: right;
    padding-right: var(--space-1);
  }
}
.embed-container {
  clear: both;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.facebook-wrapper {
  max-width: 500px;
  @apply --center;
}
